import React from 'react'
import success  from "../../images/success.png"
import { Link } from 'react-router-dom'
const PasswordResetSuccess = () => {
  return (
    <div className='flex flex-col h-screen bg-blue-200 justify-center items-center'>
    <img src={success} className='h-96' alt="" />
    <h1 className='font-bold text-3xl tracking-widest text-center'>Password Reset successfully</h1>
    <p className='p-4 tracking-wide text-slate-500'>You can use your newly set password to login</p>
    <Link to="/" className='px-3 py-2 rounded hove:bg-blue-900 text-white bg-[#022249]'>Login</Link>
</div>
  )
}

export default PasswordResetSuccess