import React from 'react'
import { Link } from 'react-router-dom';
const MenuCards = ({icon, to, name}) => {
  return (
    <Link to={to} className='px-16 py-8 md:w-2/12 sm:w-1/4 w-3/4  rounded-md border border-slate-400 border-solid shadow-md flex flex-col h-36 justify-around items-center'>
        {icon}
        <p className='font-bold'>{name}</p>
    </Link>
  )
}

export default MenuCards