import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {string, z } from "zod";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {AiOutlineClose} from "react-icons/ai"
import LoadingButton from "../../components/loading/LoadingButton";
import { updateUser } from "../../redux/allUsersSlice";
const schema = z.object({
 
  username:string().min(1 ,{
    message: "Username is required",
  }),
  email:string().min(3,{
    message: "Owner contact is required",
  }),
  phone:string().min(1,{
    message: "Box label is required",
  }),
  password:string().min(1,{
    message: "Start date is required",
  }),
  

  
});
const UpdateUser = ({setOpenEdit, user}) => {
  const {currentUser} = useSelector((state) => state.user)
  const [userType, setUserType] = useState(user.userType)
  const { register, handleSubmit, formState } = useForm({
    defaultValues: {username: user.username, email: user.email, phone:user.phone, password:""},
    resolver: zodResolver(schema),
  });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false) 
  const { errors } = formState;
  
  axios.defaults.headers.common = {
    'Authorization': 'Bearer ' + currentUser.accessToken
};
const handleUserType =(e)=>{
  setUserType(e.target.value)
}
  const handleAdd = async (formValues) => {
    setLoading(true)
    //call api
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API}users/update/${user._id}`,
        { ...formValues, userType: userType },
      );
      setLoading(false)
       dispatch(updateUser(res.data))
       setOpenEdit(false)
       toast.success("User updated successfully")
    } catch (error) {
      setLoading(false)
        console.log(error)
       error.message === "Network Error"?toast.error("Connection to the server failed"):toast.error(error.response.data.error.message);
    }
  };
  return (
                
<div className="fixed inset-0 bg-[rgba(0,0,0,0.5)] flex items-center justify-center z-50 overflow-auto max-h-screen">
<div className="max-w-4xl p-6 mx-auto bg-white rounded-md shadow-md dark:bg-gray-800">
    <div className="flex justify-between">
          <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white">
            Update User
          </h2>
          <AiOutlineClose
          className='cursor-pointer'
            size={30}
            onClick={() => {
              setOpenEdit(false);
            }}
          />
        </div>
    <form onSubmit={handleSubmit(handleAdd)}>
        <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
            
            <div>
                <label className="text-gray-700 dark:text-gray-200" for="username">Username</label>
                <input id="username" {...register("username")} type="text" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/>
                <p className="text-red-500 text-sm">{errors.username?.message}</p>
            </div>

            <div>
                <label className="text-gray-700 dark:text-gray-200" for="email">Email</label>
                <input {...register("email")} id="email" type="email" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/>
                <p className="text-red-500 text-sm">{errors.email?.message}</p>
            </div>

            <div>
                <label className="text-gray-700 dark:text-gray-200" for="phone">Phone</label>
                <input id="phone" {...register("phone")} type="number" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/>
                <p className="text-red-500 text-sm">{errors.phone?.message}</p>
            </div>

            <div>
                <label className="text-gray-700 dark:text-gray-200" for="password">User Type</label>
                <select onChange={handleUserType} defaultValue="user" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring">
                  <option value="user">User</option>
                  <option value="admin">Admin</option>
                </select>
                
            </div>
           
            <div>
                <label className="text-gray-700 dark:text-gray-200" for="password">Password</label>
                <input id="password" {...register("password")} type="password" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/>
                <p className="text-red-500 text-sm">{errors.password?.message}</p>
            </div>
            
        </div>

        <div className="flex justify-end mt-6">
        <button className="px-6 py-3 mt-4 w-full text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#022249] rounded hover:bg-blue-900 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50">
            {loading?<div className="flex justify-center gap-4 items-center"><LoadingButton/> <p>Please wait...</p></div>:"UPDATE USER"}
                </button>
        </div>
    </form>
</div>
</div>

        
  )
}

export default UpdateUser