import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {string, z } from "zod";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingButton from "../../components/loading/LoadingButton";
import InvalidLink from "./InvalidLink";
import PageNotFound from "../errors/PageNotFound";
import LoadingPage from "../../components/loading/LoadingPage";
const schema = z.object({
      password: string(),
      confirm: string(),
  
})
.refine((data) => data.password === data.confirm, {
  message: "Passwords don't match",
  path: ["confirm"],
});
const ResetPassword = () => {
  const { register, handleSubmit, formState } = useForm({
    defaultValues: { name: "", password: ""},
    resolver: zodResolver(schema),
  });
  const [loading, setLoading] = useState(false) 
  const { errors } = formState;
  const navigate = useNavigate();
  const [loadingPage, setLoadingPage] = useState(true)
  const id = useLocation().pathname.split("/")[2]
  const token = useLocation().pathname.split("/")[3]
  const [validLink, setValidLink]= useState(false);
  const [invalidUrl, setInvalidUrl] =  useState(false);

  const check = async()=>{
    try {
      await axios.get( `${process.env.REACT_APP_API}auth/verify/${id}/${token}`)
      setValidLink(true)
      setLoadingPage(false)
    } catch (error) {
      console.log("error")
      setLoadingPage(false)
      return <InvalidLink/>
    }
  }

  if(id === undefined || token === undefined){
    setInvalidUrl(true)
  }else{
   check();
  }

  const handleResetPassword = async (formValues) => {
    setLoading(true)
    //call api
    try {
       await axios.post(
        `${process.env.REACT_APP_API}auth/reset/${id}/${token}`,
        {password: formValues.password} ,
      );
      setLoading(false)
        navigate("/password-reset/success")
    } catch (error) {
      setLoading(false)
        console.log(error)
       error.message === "Network Error"?toast.error("Connection to the server failed"):toast.error(error.response.data.error.message);
    }
  };
  return  (
    <div>
      {loadingPage? <div className="flex flex-col justify-center items-center h-screen"><LoadingPage/></div>: invalidUrl? <PageNotFound/>: validLink? <section className='flex flex-col w-full items-center justify-center h-screen bg-[#7FD4EF]'>

<div className="md:w-1/2 w-full overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
    <div className="px-6 py-4">
        

        <h3 className="mt-3 text-2xl mb-4 font-bold uppercase text-center text-black tracking-wide dark:text-gray-200">Loading Dock system</h3>

        <p className="mt-1 text-center text-gray-500 mb-4 dark:text-gray-400">Reset Password</p>

        <form onSubmit={handleSubmit(handleResetPassword)}>
        <div className="w-full mt-8">
                <input  {...register("password")} className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-500 bg-white border rounded-lg dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-blue-300" type="password" placeholder="Password" aria-label="Password" />
                <p className="text-red-500 text-sm">{errors.password?.message}</p>
            </div>

            <div className="w-full mt-8">
                <input  {...register("confirm")}  className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-500 bg-white border rounded-lg dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-blue-300" type="password" placeholder="Confirm Password" aria-label="Confirm Password" />
                <p className="text-red-500 text-sm">{errors.confirm?.message}</p>
            </div>

            
            <button className="px-6 py-3 mt-4 w-full text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#022249] rounded hover:bg-blue-900 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50">
            {loading?<div className="flex justify-center gap-4 items-center"><LoadingButton/> <p>Please wait...</p></div>:"RESET PASSWORD"}
                </button>
        </form>
    </div>

   
</div>
</section>: <InvalidLink/>}
<ToastContainer/>
    </div>
  )
}

export default ResetPassword