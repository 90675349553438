
import { createSlice } from '@reduxjs/toolkit';

const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState: {
    vehicle: [],
  },
  reducers: {
    addVehicle: (state, action) => {
        // add a new Vehicle
        state.vehicle.push({ ...action.payload });
      
    },
    fetchAll: (state, action)=>{
        state.vehicle = action.payload;
    },
    updateVehicle: (state, action)=>{
      const item = state.vehicle.find((item) => item._id === action.payload._id);
        item.vehicelOwner = action.payload.vehicelOwner
        item.ownerContact = action.payload.ownerContact
        item.vehicleMake = action.payload.vehicleMake
        item.vehicleModel = action.payload.vehicleModel
        item.modelYear = action.payload.modelYear
        item.arrivalDate = action.payload.arrivalDate
        item.licencePlate = action.payload.licencePlate
        item.amountPerMonth = action.payload.amountPerMonth
    },
    
    removeItem: (state, action) => {
      const removeItem = state.vehicle.filter((item) => item._id !== action.payload._id);
      state.vehicle = removeItem;
    },
    updatePayments: (state,action)=>{
      const item = state.vehicle.find((item) => item._id === action.payload._id);
      item.payments.push({amount: action.payload.amount, paymentDate: action.payload.paymentDate,  paymentId: action.payload.paymentId})
    },
    removePayment:(state,action)=>{
      const item = state.vehicle.find((item) => item._id === action.payload._id);
      item.payments.splice(item.payments.findIndex((p)=> p.paymentId===action.payload.paymentId),1)
    },
    updateVehicleFiles:(state, action)=>{
      const item = state.vehicle.find((item) => item._id === action.payload._id);
      item.files.push({fileName: action.payload.fileName, fileLink: action.payload.fileLink})
    },
    deleteVehicleFile: (state, action)=>{
      const item = state.vehicle.find((item) => item._id === action.payload._id);
      item.files.splice(item.files.findIndex((f)=> f.fileName===action.payload.fileName),1)
    }
  },
});

export const vehicleReducer = vehicleSlice.reducer;
export const {
  addVehicle,
  updateVehicle,
  fetchAll,
  removeItem,
  updatePayments,
  removePayment,
  updateVehicleFiles,
  deleteVehicleFile
} = vehicleSlice.actions;