
import { createSlice } from '@reduxjs/toolkit';

const boxSlice = createSlice({
  name: 'box',
  initialState: {
    box: [],
  },
  reducers: {
    addBox: (state, action) => {
        // add a new box
        state.box.push({ ...action.payload });
      
    },
    fetchAll: (state, action)=>{
        state.box = action.payload;
    },
    updateBox: (state, action)=>{
      const item = state.box.find((item) => item._id === action.payload._id);
        item.ownerOfBox = action.payload.ownerOfBox
        item.ownerContact = action.payload.ownerContact
        item.boxLabel = action.payload.boxLabel
        item.startDate = action.payload.startDate
        item.amountPerMonth = action.payload.amountPerMonth
        
    },
    
    removeItem: (state, action) => {
      const removeItem = state.box.filter((item) => item._id !== action.payload._id);
      state.box = removeItem;
    },
    updatePayments: (state,action)=>{
      const item = state.box.find((item) => item._id === action.payload._id);
      item.payments.push({amount: action.payload.amount, paymentDate: action.payload.paymentDate,  paymentId: action.payload.paymentId})
    },
    removePayment:(state,action)=>{
      const item = state.box.find((item) => item._id === action.payload._id);
      item.payments.splice(item.payments.findIndex((p)=> p.paymentId===action.payload.paymentId),1)
    }
    
  },
});

export const boxReducer = boxSlice.reducer;
export const {
  addBox,
  updateBox,
  fetchAll,
  removeItem,
  updatePayments,
  removePayment
} = boxSlice.actions;