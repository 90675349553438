import React from 'react'
import { AiOutlineClose } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { removeItem } from '../../redux/containerSlice';
import axios from 'axios';
import { toast } from 'react-toastify';

const ConfirmDeleteModal = ({setOpenDelete, record}) => {
    const dispatch = useDispatch()
    // type should be same as state
    const handleDelete =async()=>{
        try {
            console.log(record)
            await axios.delete(`${process.env.REACT_APP_API}containers/delete/${record._id}`, {withCredentials:true})
            //remove from storage
            //TODO -check all states to determine 
            dispatch(removeItem(record));
            setOpenDelete(false);
        } catch (error) {
            console.log(error)
            toast.error("An error occurred")
        }
        

    }
  return (
    <div className="fixed inset-0 bg-[rgba(0,0,0,0.5)] flex items-center justify-center z-50 overflow-auto max-h-screen">
<div className="max-w-4xl p-6 mx-auto bg-white rounded-md shadow-md dark:bg-gray-800">
    <div className="flex justify-between">
          <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white">
            Confirm Delete
          </h2>
          <AiOutlineClose
            size={30}
            onClick={() => {
              setOpenDelete(false);
            }}
          />
        </div>
   <p className='p-10'>Are you sure you want to delete container?</p>
            <div className='flex items-center justify-between'>
                <button onClick={handleDelete} className='px-3 py-2 rounded bg-[#022249] hover:bg-blue-900 text-white'>Ok</button>
                <button onClick={() => {
              setOpenDelete(false);
            }} className='px-3 py-2 rounded bg-red-500 hover:bg-red-900 text-white'>Cancel</button>
            </div>

</div>
</div>
  )
}

export default ConfirmDeleteModal