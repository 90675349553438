import React from 'react'
import MenuCards from '../../components/cards/MenuCards'
import Footer from '../../components/containers/Footer'
import { GoContainer } from "react-icons/go";
import { BsFillBoxFill } from "react-icons/bs";
import { TbTruckDelivery } from "react-icons/tb";
import Breadcrump from '../../components/containers/Breadcrump';
import { deleteAll } from '../../redux/containerSlice';
import { useDispatch } from 'react-redux';
const MainPage = () => {
  const dispatch = useDispatch()
  dispatch(deleteAll())
    const list = [
        
    ]
  return (
    <>
    <Breadcrump list={list}/>
        <div className='flex flex-col min-h-[50vh] items-center justify-center mb-16'>
            <div className='flex items-center justify-around w-full flex-wrap gap-10 mt-4'>
                <MenuCards to="containers" name="Container" icon={<GoContainer size={30}/>}/>
                <MenuCards to="boxes" name="Boxes" icon={<BsFillBoxFill size={30} />}/>
                <MenuCards to="vehicles" name="Vehicles" icon={<TbTruckDelivery size={30} />}/>
            </div>
        </div>
        <Footer/>
       
    </>
  )
}

export default MainPage