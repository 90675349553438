import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {string, z } from "zod";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import LoadingButton from "../../components/loading/LoadingButton";
const schema = z.object({
  email: string().min(1, {
    message: "Email field must be filled",
  }).email("This email is not valid"),
  
  
});
const ForgotPassword = () => {
  const { register, handleSubmit, formState } = useForm({
    defaultValues: { email: ""},
    resolver: zodResolver(schema),
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false) 
  const { errors } = formState;

  const handleForgotPassword = async (formValues) => {
    setLoading(true)
    //call api
    try {
       await axios.post(
        `${process.env.REACT_APP_API}auth/forgot`,
        { ...formValues },
      );
      setLoading(false)
        navigate("/forgot/success")
    } catch (error) {
      setLoading(false)
        console.log(error)
       error.message === "Network Error"?toast.error("Connection to the server failed"):toast.error(error.response.data.error.message);
    }
  };
  return (
   
    <section className='flex flex-col w-full items-center justify-center h-screen bg-[#7FD4EF]'>

<div className="md:w-1/2 w-full overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
    <div className="px-6 py-4">
        

        <h3 className="mt-3 text-2xl mb-4 font-bold uppercase text-center text-black tracking-wide dark:text-gray-200">Loading Dock system</h3>

        <p className="mt-1 text-center text-gray-500 mb-4 dark:text-gray-400">Forgot Password</p>

        <form onSubmit={handleSubmit(handleForgotPassword)}>
            <div className="w-full mt-8">
                <input  {...register("email")} className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-500 bg-white border rounded-lg dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-blue-300" type="email" placeholder="Email" aria-label="Email" />
                <p className="text-red-500 text-sm">{errors.email?.message}</p>
            </div>

           

            <div className="flex items-center justify-between mt-4">
              <p></p>
                <Link to="/" className="text-sm text-gray-600 dark:text-gray-200 hover:text-gray-500">Back to Login</Link>

                
            </div>
            <button className="px-6 py-3 mt-4 w-full text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#022249] rounded hover:bg-blue-900 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50">
            {loading?<div className="flex justify-center gap-4 items-center"><LoadingButton/> <p>Please wait...</p></div>:"SUBMIT"}
                </button>
        </form>
    </div>

   
</div>
<ToastContainer/>
</section>

  )
}

export default ForgotPassword