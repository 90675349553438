/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";

import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { AiOutlineClose } from "react-icons/ai";
import LoadingButton from "../../components/loading/LoadingButton";
import { removePayment, updatePayments } from "../../redux/vehicleSlice";


const ViewVehiclePayment = ({ setOpenPayments, record }) => {
  const { currentUser } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  axios.defaults.headers.common = {
    Authorization: "Bearer " + currentUser.accessToken,
  };

  const [input, setInput] = useState({});
  const vehicles = useSelector((state) => state.vehicle);

  //get payments from the specified vehicle
  const vehicle = vehicles.vehicle.filter((c) => {
    return c._id === record._id;
  });
  let totalAmount = 0;
vehicle[0].payments.forEach((el) => totalAmount += Number(el.amount));
  const handleUpload = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (input.amount === "") {
      toast.warning("Please input amount");
    } else {
      try {
        const date = new Date().toString();
        const paymentId = Date.now().toString(36) + Math.random().toString(36).substring(13);
        await axios.post(
          `${process.env.REACT_APP_API}vehicles/addPayment/${record._id}`,
          { ...input, paymentDate: date , paymentId: paymentId}
        );

       dispatch(updatePayments({_id: record._id, paymentId: paymentId, amount: input.amount, paymentDate: date  }))
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };
  const handleChange = (e) => {
    setInput((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
 const handleDelete =async(p)=>{
  try {
    await axios.delete(
      `${process.env.REACT_APP_API}vehicles/removePayment/${record._id}/${p.paymentId}`
    );
    dispatch(removePayment({_id: record._id, paymentId: p.paymentId}))
  } catch (error) {
    console.log(error)
  }
 }

  return (
    <div className="fixed inset-0 bg-[rgba(0,0,0,0.5)] flex items-center justify-center z-50 overflow-auto max-h-screen">
      <div className="max-w-4xl p-6 mx-auto bg-white md:w-1/2 w-full rounded-md shadow-md dark:bg-gray-800">
        <div className="flex justify-between">
          <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white">
            Payments
          </h2>
          <AiOutlineClose
            className="cursor-pointer"
            size={30}
            onClick={() => {
              setOpenPayments(false);
            }}
          />
        </div>
        {vehicle[0].payments.length > 0 ? (
          <div>
            {vehicle[0].payments.map((p) => (
              <div className="flex items-center justify-between gap-6 border border-solid border-slate-300 flex-wrap md:px-10 py-2">
                <div className="grid gap-6 grid-cols-2">
                  <p>Amount</p>
                  <p>{p.amount}</p>
                </div>
                <div className="grid gap-6 grid-cols-2">
                  <p>Date</p>
                  <p>{p.paymentDate}</p>
                </div>
                <button onClick={()=>handleDelete(p)} className="flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#022249] rounded-lg hover:bg-blue-900 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">Delete</button>
              </div>
            ))}
          </div>
        ) : (
          <div className="p-20 font-light text-xl text-center"> No Payment made</div>
        )}
        <p className="text-center p-2">Total Amount = {totalAmount}</p>
        <p className="text-center p-2">Balance = {record.balance-totalAmount}</p>
        <form>
          <div className="grid grid-cols-1 gap-6 mt-4">
          <div>
                <label className="text-gray-700 dark:text-gray-200" for="amount">Amount</label>
                <input id="username" onChange={handleChange} name="amount"  type="text" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/>
               
            </div>
          </div>

          <div className="flex justify-end mt-6">
            <button
              type="button"
              onClick={handleUpload}
              className="px-6 py-3 mt-4 w-full text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#022249] rounded hover:bg-blue-900 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
            >
              {loading ? (
                <div className="flex justify-center gap-4 items-center">
                  <LoadingButton /> <p>Please wait...</p>
                </div>
              ) : (
                "ADD PAYMENT"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ViewVehiclePayment;
