/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";
import { FaDownload } from "react-icons/fa";   
import {saveAs} from "file-saver" 
import { useDispatch, useSelector } from 'react-redux';
import "react-toastify/dist/ReactToastify.css";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject
} from "firebase/storage";
import app from "../../firebase.js";
import axios from "axios";
import { IoMdClose } from "react-icons/io";
import {AiOutlineClose} from "react-icons/ai"
import LoadingButton from "../../components/loading/LoadingButton";
import { Link } from "react-router-dom";
import { deleteContainerFile, updateContainerFiles } from "../../redux/containerSlice.js";

const ViewFilesModal = ({setOpenFiles, record}) => {
  const {currentUser} = useSelector((state) => state.user)
 
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false) 
  
  axios.defaults.headers.common = {
    'Authorization': 'Bearer ' + currentUser.accessToken
};

const [selectedFile, setSelectedFile] = useState();
  const [filePerc, setFilePerc] = useState(0);
  const [input, setInput] = useState({});
  const containers = useSelector((state) => state.container)
  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);
  const storage = getStorage(app);
  const downloadFile =(fileName)=>{
    getDownloadURL(ref(storage, fileName))
  .then((url) => {
    
    // This can be downloaded directly:
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = (event) => {
      const blob = xhr.response;
      saveAs(blob, fileName);
    };
    xhr.open('GET', url);
    xhr.send();

   
  })
  .catch((error) => {
    toast.error("Error downloading file")
    console.log(error)
  });
  }

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  //get files from the specified container
  const container = containers.container.filter((c)=>{
    return c._id === record._id
  })
  
  const uploadFile = (file) => {
    
    const fileName = file.name +"_"+new Date().getTime()  ;
    const storageRef = ref(storage, fileName);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setFilePerc(Math.round(progress));
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setInput((prev) => {
            return { ...prev, fileLink: downloadURL, fileName: fileName };
          });
        });
      }
    );
  }; // I've kept this example simple by using the first image instead of multiple
  useEffect(() => {
    selectedFile && uploadFile(selectedFile);
  }, [selectedFile]);
  const handleUpload = async (e) => {
    setFilePerc(0)
    setLoading(true)
    e.preventDefault();
    if(filePerc !== 100){
      toast.warning("Wait for upload")
    }else{
      try {
         await axios.post(
          `${process.env.REACT_APP_API}containers/addFile/${record._id}`,
          { ...input },
        );
        dispatch(updateContainerFiles({_id: record._id, fileName: input.fileName, fileLink: input.fileLink}))
        setLoading(false)
      } catch (error) {
        console.log(error.response.data.message);
        setLoading(false)
      }
    }
  };
  const deleteFile = async(file)=>{
    try {
      //delete the record from db
      await axios.delete(
        `${process.env.REACT_APP_API}containers/deleteFile/${record._id}/${file.fileName}`
      );
      const deleteRef = ref(storage, file.fileName);
      //delete from storage
        deleteObject(deleteRef).then(()=>{
          toast.success("File deleted")
        }).catch((error) => {
          toast.error("Error deleting file from storage")
        });
        dispatch(deleteContainerFile({_id: record._id, fileName: file.fileName}))
      
    } catch (error) {
      console.log(error)
      toast.error("Error deleting file from db")
    }
    
  }
  
  return (
                
<div className="fixed inset-0 bg-[rgba(0,0,0,0.5)] flex items-center justify-center z-50 overflow-auto max-h-screen">
<div className="max-w-4xl p-6 mx-auto bg-white md:w-1/2 w-full rounded-md shadow-md dark:bg-gray-800">
    <div className="flex justify-between">
          <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white">
            Files associated with Container number {record.containerNumber}
          </h2>
          <AiOutlineClose
          className='cursor-pointer'
            size={30}
            onClick={() => {
                setOpenFiles(false);
            }}
          />
        </div>
        {container[0].files.length>0?<div>
          {
          container[0].files.map((file)=>(
            <div className="flex items-center justify-between gap-6 border border-solid border-slate-300 flex-wrap md:px-10 py-2">
            <p className="font-bold">{file.fileName}</p>
           <div className=" w-full flex justify-between items-center">
           <Link onClick={()=>downloadFile(file.fileName)} className="flex gap-2 items-center w-36 px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#022249] rounded-lg hover:bg-blue-900 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
            <FaDownload />
                <span>Download</span>
            </Link>
            <IoMdClose onClick={()=>{deleteFile(file)}} className="cursor-pointer" size={24} />
           </div>

        </div>
          ))
        }
        </div>: <div className="p-20 font-light text-xl"> No Files Found</div>}
    <form>
        <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
           
           
            {filePerc > 0 ? (
              "Uploading:" + filePerc + "%"
            ) : filePerc ===100? "Uploaded": (
            <div>
                <label className="text-gray-700 dark:text-gray-200" for="file">File Upload</label>
                <input id="file" type="file"  onChange={onSelectFile} className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/>
                
            </div>
)}
            
        </div>

        <div className="flex justify-end mt-6">
        <button type="button" onClick={handleUpload} className="px-6 py-3 mt-4 w-full text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#022249] rounded hover:bg-blue-900 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50">
            {loading && filePerc <100?<div className="flex justify-center gap-4 items-center"><LoadingButton/> <p>Please wait...</p></div>:"ADD FILE"}
                </button>
        </div>
    </form>
</div>
</div>

        
  )
}

export default ViewFilesModal