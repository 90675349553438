import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {string, z } from "zod";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import LoadingButton from "../../components/loading/LoadingButton";
import { loginSuccess } from "../../redux/userSlice";
const schema = z.object({
 
  username:string().min(1 ,{
    message: "Username is required",
  }),
  email:string().min(3,{
    message: "Email is required",
  }),
  phone:string().min(1,{
    message: "Phone number is required",
  }),
  password:string().min(1,{
    message: "Password is required",
  }),


  
});
const Account = () => {
    const {currentUser} = useSelector((state) => state.user)
  const { register, handleSubmit, formState } = useForm({
    defaultValues: { username: currentUser.username, email: currentUser.email, phone:currentUser.phone, password:"", userType: currentUser.userType},
    resolver: zodResolver(schema),
  });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false) 
  const { errors } = formState;
  console.log(currentUser.userType)
  axios.defaults.headers.common = {
    'Authorization': 'Bearer ' + currentUser.accessToken
};

  const handleUpdate = async (formValues) => {
    setLoading(true)
    //call api
    try {
      console.log(formValues)
      const res = await axios.put(
        `${process.env.REACT_APP_API}users/update/${currentUser._id}`,
        { ...formValues, userType: currentUser.userType },
      );
      setLoading(false)
       dispatch(loginSuccess(res.data))
       toast.success("Account updated successfully")
    } catch (error) {
      setLoading(false)
        console.log(error)
       error.message === "Network Error"?toast.error("Connection to the server failed"):toast.error(error.response.data.error.message);
    }
  };
  return (
    <div className="max-w-4xl p-6 mt-6 mx-auto bg-white rounded-md shadow-md dark:bg-gray-800">
    <div className="flex justify-between">
          <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white">
            Update Account
          </h2>
          
        </div>
    <form onSubmit={handleSubmit(handleUpdate)}>
        <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
            <input type="hidden" {...register("userId")}/>
            <div>
                <label className="text-gray-700 dark:text-gray-200" for="username">Username</label>
                <input id="username" {...register("username")} type="text" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/>
                <p className="text-red-500 text-sm">{errors.username?.message}</p>
            </div>

            <div>
                <label className="text-gray-700 dark:text-gray-200" for="email">Email</label>
                <input {...register("email")} id="email" type="text" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/>
                <p className="text-red-500 text-sm">{errors.email?.message}</p>
            </div>

            <div>
                <label className="text-gray-700 dark:text-gray-200" for="phone">Phone Number</label>
                <input id="phone" {...register("phone")} type="number" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/>
                <p className="text-red-500 text-sm">{errors.phone?.message}</p>
            </div>

            <div>
                <label className="text-gray-700 dark:text-gray-200" for="password">Password</label>
                <input id="password" {...register("password")} type="password" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/>
                <p className="text-red-500 text-sm">{errors.password?.message}</p>
            </div>
           

            
        </div>

        <div className="flex justify-end mt-6">
        <button className="px-6 py-3 mt-4 w-full text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#022249] rounded hover:bg-blue-900 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50">
            {loading?<div className="flex justify-center gap-4 items-center"><LoadingButton/> <p>Please wait...</p></div>:"UPDATE ACCOUNT"}
                </button>
        </div>
    </form>
    <ToastContainer/>
</div>
  )
}

export default Account