import React from 'react'
import noconnection from "../../images/noconnection.svg"
const NoConnection = () => {
  return (
    <div className='flex flex-col justify-center min-h-[90vh] items-center'>
        <img src={noconnection} className='h-48' alt="" />
        <h1 className='font-bold py-4 tracking-widest'>You are Offline</h1>
        <p className='tracking-wide'>Our application needs an active internet connection</p>
    </div>
  )
}

export default NoConnection