/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import TablePagination from "../../components/pagination/Pagination";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { mkConfig, generateCsv, download } from "export-to-csv";
import LoadingPage from "../../components/loading/LoadingPage";
import Breadcrump from "../../components/containers/Breadcrump";
import { MdEdit } from "react-icons/md";
import { LuDelete, LuFileStack } from "react-icons/lu";
import { MdOutlineAdd } from "react-icons/md";
import { BsFiletypeCsv } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchAll } from "../../redux/vehicleSlice";
import AddVehicleModal from "../../components/modals/AddVehicleModal";
import UpdateVehicleModal from "../../components/modals/UpdateVehicleModal";
import ConfirmVehicleDelete from "../../components/modals/ConfirmVehicleDelete";
import ViewVehiclePayment from "../../components/modals/ViewVehiclePayment";
import ViewVehicleFiles from "../../components/modals/ViewVehicleFiles";
const Vehicles = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchOption, setSearchOption] = useState("vehicleOwner")
  const [record, setRecord] = useState({});
  const [openDelete,setOpenDelete] = useState(false);
  const [openFiles,setOpenFiles] = useState(false);
  const [openPayments, setOpenPayments] = useState(false)
  const [postsPerPage, setPostsPerPage] = useState(5);
  const [openEdit, setOpenEdit] = useState(false);
  const csvConfig = mkConfig({ useKeysAsHeaders: true, filename:"Loading Dock Vehicles "+moment().format()   });
  let [filteredData] = useState();
  const {currentUser} = useSelector((state) => state.user)
  const vehicles = useSelector((state) => state.vehicle)
  axios.defaults.headers.common = {
    'Authorization': 'Bearer ' + currentUser.accessToken
};
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fetchVehicles = async () => {
    setLoading(true)
    try {
      const res = await axios.get(`${process.env.REACT_APP_API}vehicles/${currentUser._id}`, { withCredentials: true });
      dispatch(fetchAll(res.data))
      setLoading(false)
    } catch (error) {
      console.log(error)
      error.response.data.error.status === 401? navigate("/"): toast.error(error.response.data.error.message)
      setLoading(false)
    }
  };
  useEffect(() => {
    fetchVehicles();
  }, []);
  const modifiedData = vehicles.vehicle.map(({ ...item }) => ({
    ...item,
    key: item._id,
  }));
  const csvData = vehicles.vehicle.map(({...item}, index)=>({
    no: index+1,
    ownerOfBox: item.ownerOfBox,
    ownerContact: item.ownerContact,
    boxLabel: item.boxLabel,
    startDate:item.startDate,
    amountPaid: item.amountPaid,
  }))
  let csv;
  if(csvData.length>0){
     csv = generateCsv(csvConfig)(csvData);
  }
  
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentVehicles = modifiedData.slice(firstPostIndex, lastPostIndex);
  console.log(searchOption)
  const handleSelectChange = (e)=>{
    setSearchOption(e.target.value);
  }
  const handleSearch = (e) => {
    let searchValue = e.target.value;
    if (e.target.value === "") {
      fetchVehicles();
    } else {
      if(searchOption === "vehicleOwner"){
        filteredData = modifiedData.filter((value) => {
          return value.vehicleOwner.toLowerCase().includes(searchValue.toLowerCase());
        });
      }else if(searchOption === "ownerContact"){
        filteredData = modifiedData.filter((value) => {
          return value.ownerContact.toLowerCase().includes(searchValue.toLowerCase());
        });
      }
      else if(searchOption === "vehicleMake"){
        filteredData = modifiedData.filter((value) => {
          return value.vehicleMake.toLowerCase().includes(searchValue.toLowerCase());
        });
      }
      else if(searchOption === "vehicleModel"){
        filteredData = modifiedData.filter((value) => {
          return value.vehicleModel.toLowerCase().includes(searchValue.toLowerCase());
        });
      }
      else if(searchOption === "modelYear"){
        filteredData = modifiedData.filter((value) => {
          return value.modelYear.toLowerCase().includes(searchValue.toLowerCase());
        });
      }
      else if(searchOption === "arrivalDate"){
        filteredData = modifiedData.filter((value) => {
          return value.arrivalDate.toLowerCase().includes(searchValue.toLowerCase());
        });
      }
      
      
      dispatch(fetchAll(filteredData))
      setCurrentPage(1);
    }
  };

  const handleDelete = (record)=>{
    setOpenDelete(true)
    setRecord(record)
  }
  const handleUpdate = (record)=>{
    setOpenEdit(true)
    setRecord(record)
  }
  const handleViewPayment = (record)=>{
    setOpenPayments(true)
    setRecord(record)
  }
  const handleView = (record)=>{
    setOpenFiles(true)
    setRecord(record)
  }
  //breadcrum
  const list = [
    {
      link: "vehicles",
      name: "Vehicles",
    },
  ];
  const [openAdd, setOpenAdd] = useState(false);
  return (
    <>
      <Breadcrump list={list} />
      <div className="flex flex-col  md:flex-row items-center md:justify-between  md:px-24 px-6">
        <div className="flex gap-4 items-center  md:w-1/2 w-full">
          <select onChange={handleSelectChange} defaultValue="ownerOfBox" className="block  mt-2 w-full placeholder-gray-400/70 dark:placeholder-gray-500 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-blue-300">
            <option value="vehicleOwner">Vehicle Owner</option>
            <option value="ownerContact">Owner Contact/Phone</option>
            <option value="vehicleMake">Vehicle Make</option>
            <option value="vehicleModel">Vehicle Model</option>
            <option value="modelYear">Model Year</option>
            <option value="arrivalDate">Arrival Date</option>
          </select>
          <input
            type="text"
            onChange={handleSearch}
            placeholder="Search..."
            className="block  mt-2 w-full placeholder-gray-400/70 dark:placeholder-gray-500 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-blue-300"
          />
        </div>

        <div className="flex gap-4 items-center mt-4" >
        <button onClick={()=>setOpenAdd(true)} className="flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#022249] rounded-lg hover:bg-blue-900 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
          <MdOutlineAdd />

          <span className="mx-1">Add</span>
        </button>

        <button onClick={()=>{download(csvConfig)(csv)}} className="flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#022249] rounded-lg hover:bg-blue-900 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
        <BsFiletypeCsv />

          <span className="mx-1">Export</span>
        </button>
        </div>
      </div>
      <p className="md:pl-24 pl-8 mt-2 font-bold tracking-wide">{modifiedData.length}  results</p>
      {currentVehicles.length >0?<div className="p-5  bg-scale-100">
        <div className="overflow-auto w-11/12 lg:w-11/12 md:w-full mx-auto rounded-lg shadow hidden lg:block md:block xl:block">
          <table className="w-full">
            <thead className="bg-gray-50 border-b-2 border-gray-200">
              <tr>
                <th className="p-3  whitespace-nowrap text-sm font-semibold tracking-wide text-left">
                  No.
                </th>
                <th className="p-3 whitespace-nowrap text-sm font-semibold tracking-wide text-left">
                  Vehicle Owner
                </th>
                <th className="p-3 whitespace-nowrap text-sm font-semibold tracking-wide text-left">
                  Owner Phone Number
                </th>
                <th className="p-3 whitespace-nowrap text-sm font-semibold tracking-wide text-left">
                  {" "}
                  Vehicle Make
                </th>
                <th className="p-3 whitespace-nowrap text-sm font-semibold tracking-wide text-left">
                  Vehicle Model
                </th>
                <th className="p-3 whitespace-nowrap text-sm font-semibold tracking-wide text-left">
                  Model Year
                </th>
                <th className="p-3 whitespace-nowrap text-sm font-semibold tracking-wide text-left">
                  Arrival Date
                </th>
                
                <th className="p-3 whitespace-nowrap text-sm font-semibold tracking-wide text-left">
                  Monthly Amount
                </th>
                <th className="p-3 whitespace-nowrap text-sm font-semibold tracking-wide text-left">
                  Vehicle Vin
                </th>
                <th className="p-3 whitespace-nowrap text-sm font-semibold tracking-wide text-left">
                  Vehicle Color
                </th>
                <th className="p-3 whitespace-nowrap text-sm font-semibold tracking-wide text-left">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100">
              {currentVehicles.map((vehicle, index)=>(
                <tr key={vehicle._id}>
                <td className="p-3 whitespace-nowrap text-sm text-gray-700 font-bold">
                {((currentPage-1)*5)+index+1}
                </td>
                <td className="p-3 whitespace-nowrap text-sm text-gray-700">
                  {vehicle.vehicleOwner}
                </td>
                <td className="p-3 whitespace-nowrap text-sm text-gray-700">
                {vehicle.ownerContact}
                </td>
                <td className="p-3 whitespace-nowrap text-sm text-gray-700">
                {vehicle.vehicleMake}
                </td>
                <td className="p-3 whitespace-nowrap text-sm text-gray-700">
                {vehicle.vehicleModel}
                </td>
                <td className="p-3 whitespace-nowrap text-sm text-gray-700">
                {vehicle.modelYear}
                </td>
                <td className="p-3 whitespace-nowrap text-sm text-gray-700">
                {vehicle.arrivalDate}
                </td>
               
                <td className="p-3 whitespace-nowrap text-sm text-gray-700">
                {vehicle.amountPerMonth}
                </td>
                <td className="p-3 whitespace-nowrap text-sm text-gray-700">
                {vehicle.vehicleVin}
                </td>
               
                <td className="p-3 whitespace-nowrap text-sm text-gray-700">
                {vehicle.vehicleColor}
                </td>
                
                <td className="flex gap-4 items-center py-3">
                <button onClick={()=>{handleView(vehicle)}} className="flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#022249] rounded-lg hover:bg-blue-900 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
            <LuFileStack />

                <span className="mx-1">Files</span>
              </button>
                <button onClick={()=>handleViewPayment(vehicle)} className="flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#022249] rounded-lg hover:bg-blue-900 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">Payments</button>
                  <MdEdit onClick={()=>handleUpdate(vehicle)}  className="text-green-500 cursor-pointer" size={20} />{" "}
                  <LuDelete onClick={()=>handleDelete(vehicle)} className="text-red-500 cursor-pointer" size={20} />
                </td>
              </tr>
              ))}
             
               
           
            </tbody>
          </table>
        </div>

        {/* Tablets */}
        <div className="grid sm:grid-cols-2 gap-6 grid-cols-1 md:hidden">
          {currentVehicles.map((vehicle, index)=>(
            <div className="bg-slate-50 space-y-3 p-4 rounded-lg shadow" key={vehicle._id}>
            <div className="grid grid-cols-2 gap-2">
              <p className="p-2 rounded rounded-r-3xl bg-[#022249] text-white whitespace-nowrap text-sm font-semibold tracking-wide">
                No
              </p>
              <p className="p-2 text-sm text-gray-700">{((currentPage-1)*5)+index+1}</p>
              <p className="p-2 rounded rounded-r-3xl bg-[#022249] text-white whitespace-nowrap text-sm font-semibold tracking-wide">
               Vehicle Owner
              </p>
              <p className="p-2 text-sm text-gray-700">{vehicle.vehicleOwner}</p>
              <p className="p-2 rounded rounded-r-3xl bg-[#022249] text-white whitespace-nowrap text-sm font-semibold tracking-wide">
               Owner Contact
              </p>
              <p className="p-2 text-sm text-gray-700">{vehicle.ownerContact}</p>
              <p className="p-2 rounded rounded-r-3xl bg-[#022249] text-white whitespace-nowrap text-sm font-semibold tracking-wide">
                vehicle label
              </p>
              <p className="p-2 text-sm text-gray-700">{vehicle.vehicleMake}</p>
              <p className="p-2 rounded rounded-r-3xl bg-[#022249] text-white whitespace-nowrap text-sm font-semibold tracking-wide">
               Vehicle Make
              </p>
              <p className="p-2 text-sm text-gray-700">{vehicle.vehicleModel}</p>
              <p className="p-2 rounded rounded-r-3xl bg-[#022249] text-white whitespace-nowrap text-sm font-semibold tracking-wide">
                Model Year
              </p>
              <p className="p-2 text-sm text-gray-700">{vehicle.modelYear}</p>
              <p className="p-2 rounded rounded-r-3xl bg-[#022249] text-white whitespace-nowrap text-sm font-semibold tracking-wide">
                Arrival Date
              </p>
              <p className="p-2 text-sm text-gray-700">{vehicle.arrivalDate}</p>
              <p className="p-2 rounded rounded-r-3xl bg-[#022249] text-white whitespace-nowrap text-sm font-semibold tracking-wide">
                Monthly Amount
              </p>
              <p className="p-2 text-sm text-gray-700">{vehicle.amountPerMonth}</p>
             
            </div>
            <div className="flex justify-center gap-4">
            <button onClick={()=>{handleView(vehicle)}} className="flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#022249] rounded-lg hover:bg-blue-900 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
            <LuFileStack />

                <span className="mx-1">Files</span>
              </button>
            <button onClick={()=>handleViewPayment(vehicle)} className="flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#022249] rounded-lg hover:bg-blue-900 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">Payments</button>
              <MdEdit onClick={()=>handleUpdate(vehicle)}  className="text-green-500 cursor-pointer" size={20} />{" "}
              <LuDelete onClick={()=>handleDelete(vehicle)} className="text-red-500 cursor-pointer" size={20} />
            </div>
          </div>
          ))}

         
        </div>
      </div>:(
          <div className="flex items-center mt-6 text-center border rounded-lg h-96 dark:border-gray-700">
            {loading?<div className="block w-48 mx-auto"><LoadingPage/> <p>Loading vehicles...</p></div>: <div className="flex flex-col w-full max-w-sm px-4 mx-auto">
              <div className="p-3 mx-auto text-blue-500 bg-blue-100 rounded-full dark:bg-gray-800">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
                </svg>
              </div>
              <h1 className="mt-3 text-lg text-gray-800 dark:text-white">
                No Vehicles found
              </h1>
            </div>}
          </div>
        )}
      {openAdd && <AddVehicleModal setOpenAdd={setOpenAdd}/>}
      {openPayments && <ViewVehiclePayment setOpenPayments={setOpenPayments} record={record}/>}
      {openEdit && <UpdateVehicleModal setOpenEdit={setOpenEdit} vehicle={record}/>}
      {openDelete && <ConfirmVehicleDelete setOpenDelete={setOpenDelete} record ={record} />}
      {openFiles && <ViewVehicleFiles setOpenFiles={setOpenFiles} record={record}/>}
        <ToastContainer/>
<TablePagination setPostsPerPage={setPostsPerPage}  totalPosts={modifiedData.length} postsPerPage={postsPerPage} setCurrentPage={setCurrentPage} currentPage={currentPage}/>
    </>
  );
};


export default Vehicles