/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import TablePagination from "../../components/pagination/Pagination";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { mkConfig, generateCsv, download } from "export-to-csv";
import LoadingPage from "../../components/loading/LoadingPage";
import Breadcrump from "../../components/containers/Breadcrump";
import { MdEdit } from "react-icons/md";
import { LuDelete } from "react-icons/lu";
import { MdOutlineAdd } from "react-icons/md";
import { BsFiletypeCsv } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchAll } from "../../redux/boxSlice";
import AddBoxesModal from "../../components/modals/AddBoxesModal";
import UpdateBoxesModal from "../../components/modals/UpdateBoxesModal";
import ConfirmBoxDelete from "../../components/modals/ConfirmBoxDelete";
import ViewBoxPayments from "../../components/modals/ViewBoxPayments";
const Boxes = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchOption, setSearchOption] = useState("ownerOfBox")
  const [record, setRecord] = useState({});
  const [openDelete,setOpenDelete] = useState(false);
  const [openPayments, setOpenPayments] = useState(false)
  const [postsPerPage, setPostsPerPage] = useState(5);
  const [openEdit, setOpenEdit] = useState(false);
  const csvConfig = mkConfig({ useKeysAsHeaders: true, filename:"Loading Dock Boxes "+moment().format()   });
  let [filteredData] = useState();
  const {currentUser} = useSelector((state) => state.user)
  const boxes = useSelector((state) => state.box)
  axios.defaults.headers.common = {
    'Authorization': 'Bearer ' + currentUser.accessToken
};
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fetchBoxes = async () => {
    setLoading(true)
    try {
      const res = await axios.get(`${process.env.REACT_APP_API}boxes/${currentUser._id}`, { withCredentials: true });
      dispatch(fetchAll(res.data))
      setLoading(false)
    } catch (error) {
      console.log(error)
      error.response.data.error.status === 401? navigate("/"): toast.error(error.response.data.error.message)
      setLoading(false)
    }
  };
  useEffect(() => {
    fetchBoxes();
  }, []);
  const modifiedData = boxes.box.map(({ ...item }) => ({
    ...item,
    key: item._id,
  }));
  const csvData = boxes.box.map(({...item}, index)=>({
    no: index+1,
    ownerOfBox: item.ownerOfBox,
    ownerContact: item.ownerContact,
    boxLabel: item.boxLabel,
    startDate:item.startDate,
    amountPerMonth: item.amountPerMonth,
  }))
  let csv;
  if(csvData.length>0){
     csv = generateCsv(csvConfig)(csvData);
  }
  
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentBoxes = modifiedData.slice(firstPostIndex, lastPostIndex);
  console.log(searchOption)
  const handleSelectChange = (e)=>{
    setSearchOption(e.target.value);
  }
  const handleSearch = (e) => {
    let searchValue = e.target.value;
    if (e.target.value === "") {
      fetchBoxes();
    } else {
      if(searchOption === "ownerOfBox"){
        filteredData = modifiedData.filter((value) => {
          return value.ownerOfBox.toLowerCase().includes(searchValue.toLowerCase());
        });
      }else if(searchOption === "ownerContact"){
        filteredData = modifiedData.filter((value) => {
          return value.ownerContact.toLowerCase().includes(searchValue.toLowerCase());
        });
      }
      else if(searchOption === "boxLabel"){
        filteredData = modifiedData.filter((value) => {
          return value.boxLabel.toLowerCase().includes(searchValue.toLowerCase());
        });
      }
      else if(searchOption === "startDate"){
        filteredData = modifiedData.filter((value) => {
          return value.startDate.toLowerCase().includes(searchValue.toLowerCase());
        });
      }
      
      dispatch(fetchAll(filteredData))
      setCurrentPage(1);
    }
  };

  const handleDelete = (record)=>{
    setOpenDelete(true)
    setRecord(record)
  }
  const handleUpdate = (record)=>{
    setOpenEdit(true)
    setRecord(record)
  }

  const handleViewPayment = (record)=>{
    setOpenPayments(true)
    setRecord(record)
  }

  //breadcrum
  const list = [
    {
      link: "boxes",
      name: "Boxes",
    },
  ];
  const [openAdd, setOpenAdd] = useState(false);
  return (
    <>
      <Breadcrump list={list} />
      <div className="flex flex-col  md:flex-row items-center md:justify-between  md:px-24 px-6">
        <div className="flex gap-4 items-center  md:w-1/2 w-full">
          <select onChange={handleSelectChange} defaultValue="ownerOfBox" className="block  mt-2 w-full placeholder-gray-400/70 dark:placeholder-gray-500 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-blue-300">
            <option value="ownerOfBox">Owner of Box</option>
            <option value="ownerContact">Owner Contact/Phone</option>
            <option value="boxLabel">Box Label</option>
            <option value="startDate">Start Date</option>
          </select>
          <input
            type="text"
            onChange={handleSearch}
            placeholder="Search..."
            className="block  mt-2 w-full placeholder-gray-400/70 dark:placeholder-gray-500 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-blue-300"
          />
        </div>

        <div className="flex gap-4 items-center mt-4" >
        <button onClick={()=>setOpenAdd(true)} className="flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#022249] rounded-lg hover:bg-blue-900 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
          <MdOutlineAdd />

          <span className="mx-1">Add</span>
        </button>

        <button onClick={()=>{download(csvConfig)(csv)}} className="flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#022249] rounded-lg hover:bg-blue-900 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
        <BsFiletypeCsv />

          <span className="mx-1">Export</span>
        </button>
        </div>
      </div>
      <p className="md:pl-24 pl-8 mt-2 font-bold tracking-wide">{modifiedData.length}  results</p>
      {currentBoxes.length >0?<div className="p-5  bg-scale-100">
        <div className="overflow-auto w-11/12 lg:w-11/12 md:w-full mx-auto rounded-lg shadow hidden lg:block md:block xl:block">
          <table className="w-full">
            <thead className="bg-gray-50 border-b-2 border-gray-200">
              <tr>
                <th className="p-3  whitespace-nowrap text-sm font-semibold tracking-wide text-left">
                  No.
                </th>
                <th className="p-3 whitespace-nowrap text-sm font-semibold tracking-wide text-left">
                  Owner of Box
                </th>
                <th className="p-3 whitespace-nowrap text-sm font-semibold tracking-wide text-left">
                  Owner Phone Number
                </th>
                <th className="p-3 whitespace-nowrap text-sm font-semibold tracking-wide text-left">
                  {" "}
                  Box Label
                </th>
                <th className="p-3 whitespace-nowrap text-sm font-semibold tracking-wide text-left">
                  Storage Start Date
                </th>
                <th className="p-3 whitespace-nowrap text-sm font-semibold tracking-wide text-left">
                  Amount Per Month
                </th>
                
                
                <th className="p-3 whitespace-nowrap text-sm font-semibold tracking-wide text-left">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100">
              {currentBoxes.map((box, index)=>(
                <tr key={box._id}>
                <td className="p-3 whitespace-nowrap text-sm text-gray-700 font-bold">
                {((currentPage-1)*5)+index+1}
                </td>
                <td className="p-3 whitespace-nowrap text-sm text-gray-700">
                  {box.ownerOfBox}
                </td>
                <td className="p-3 whitespace-nowrap text-sm text-gray-700">
                {box.ownerContact}
                </td>
                <td className="p-3 whitespace-nowrap text-sm text-gray-700">
                {box.boxLabel}
                </td>
                <td className="p-3 whitespace-nowrap text-sm text-gray-700">
                {box.startDate}
                </td>
                <td className="p-3 whitespace-nowrap text-sm text-gray-700">
                {box.amountPerMonth}
                </td>
             
               
                
                <td className="flex gap-4 items-center py-3">
                  <button onClick={()=>handleViewPayment(box)} className="flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#022249] rounded-lg hover:bg-blue-900 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">Payments</button>
                  <MdEdit onClick={()=>handleUpdate(box)}  className="text-green-500 cursor-pointer" size={20} />{" "}
                  <LuDelete onClick={()=>handleDelete(box)} className="text-red-500 cursor-pointer" size={20} />
                </td>
              </tr>
              ))}
             
               
           
            </tbody>
          </table>
        </div>

        {/* Tablets */}
        <div className="grid sm:grid-cols-2 gap-6 grid-cols-1 md:hidden">
          {currentBoxes.map((box, index)=>(
            <div className="bg-slate-50 space-y-3 p-4 rounded-lg shadow" key={box._id}>
            <div className="grid grid-cols-2 gap-2">
              <p className="p-2 rounded rounded-r-3xl bg-[#022249] text-white whitespace-nowrap text-sm font-semibold tracking-wide">
                No
              </p>
              <p className="p-2 text-sm text-gray-700">{((currentPage-1)*5)+index+1}</p>
              <p className="p-2 rounded rounded-r-3xl bg-[#022249] text-white whitespace-nowrap text-sm font-semibold tracking-wide">
               Owner of Box
              </p>
              <p className="p-2 text-sm text-gray-700">{box.ownerOfBox}</p>
              <p className="p-2 rounded rounded-r-3xl bg-[#022249] text-white whitespace-nowrap text-sm font-semibold tracking-wide">
               Owner Contact
              </p>
              <p className="p-2 text-sm text-gray-700">{box.ownerContact}</p>
              <p className="p-2 rounded rounded-r-3xl bg-[#022249] text-white whitespace-nowrap text-sm font-semibold tracking-wide">
                Box label
              </p>
              <p className="p-2 text-sm text-gray-700">{box.boxLabel}</p>
              <p className="p-2 rounded rounded-r-3xl bg-[#022249] text-white whitespace-nowrap text-sm font-semibold tracking-wide">
                Start Date
              </p>
              <p className="p-2 text-sm text-gray-700">{box.startDate}</p>
              <p className="p-2 rounded rounded-r-3xl bg-[#022249] text-white whitespace-nowrap text-sm font-semibold tracking-wide">
                Amount per Month
              </p>
              <p className="p-2 text-sm text-gray-700">{box.amountPerMonth}</p>
              
             
            </div>
            <div className="flex justify-center gap-4">
            <button onClick={()=>handleViewPayment(box)} className="flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#022249] rounded-lg hover:bg-blue-900 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">Payments</button>
              <MdEdit onClick={()=>handleUpdate(box)}  className="text-green-500 cursor-pointer" size={20} />{" "}
              <LuDelete onClick={()=>handleDelete(box)} className="text-red-500 cursor-pointer" size={20} />
            </div>
          </div>
          ))}

         
        </div>
      </div>:(
          <div className="flex items-center mt-6 text-center border rounded-lg h-96 dark:border-gray-700">
            {loading?<div className="block w-48 mx-auto"><LoadingPage/> <p>Loading boxes...</p></div>: <div className="flex flex-col w-full max-w-sm px-4 mx-auto">
              <div className="p-3 mx-auto text-blue-500 bg-blue-100 rounded-full dark:bg-gray-800">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
                </svg>
              </div>
              <h1 className="mt-3 text-lg text-gray-800 dark:text-white">
                No Boxes found
              </h1>
            </div>}
          </div>
        )}
      {openAdd && <AddBoxesModal setOpenAdd={setOpenAdd}/>}
      {openPayments && <ViewBoxPayments setOpenPayments={setOpenPayments} record={record}/>}
      {openEdit && <UpdateBoxesModal setOpenEdit={setOpenEdit} box={record}/>}
      {openDelete && <ConfirmBoxDelete setOpenDelete={setOpenDelete} record ={record} />}
        <ToastContainer/>
<TablePagination setPostsPerPage={setPostsPerPage}  totalPosts={modifiedData.length} postsPerPage={postsPerPage} setCurrentPage={setCurrentPage} currentPage={currentPage}/>
    </>
  );
};


export default Boxes