import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {string, z } from "zod";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {AiOutlineClose} from "react-icons/ai"
import LoadingButton from "../../components/loading/LoadingButton";
import { updateVehicle } from "../../redux/vehicleSlice";
const schema = z.object({
  userId:string().min(5,{
    required_error: "Amount paid is required",
  }),
  vehicleOwner:string().min(1 ,{
    message: "Vehicle name is required",
  }),
  ownerContact:string().min(3,{
    message: "Owner contact is required",
  }),
  vehicleMake:string().min(1,{
    message: "Vehicle make is required",
  }),
  vehicleModel:string().min(1,{
    message: "Vehicle Model is required",
  }),
  modelYear:string().min(1,{
    message: "Model year is required",
  }),
  arrivalDate:string().min(1,{
    message: "Arrival date is required",
  }),

  
});
const UpdateVehicleModal = ({setOpenEdit, vehicle}) => {
  const {currentUser} = useSelector((state) => state.user)
  const { register, handleSubmit, formState } = useForm({
    defaultValues: { userId:currentUser._id,vehicleOwner: vehicle.vehicleOwner, ownerContact: vehicle.vehicleOwner, vehicleMake:vehicle.vehicleMake, vehicleModel:vehicle.vehicleModel, modelYear:vehicle.modelYear,arrivalDate:vehicle.arrivalDate},
    resolver: zodResolver(schema),
  });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false) 
  const { errors } = formState;
  
  axios.defaults.headers.common = {
    'Authorization': 'Bearer ' + currentUser.accessToken
};

  const handleAdd = async (formValues) => {
    setLoading(true)
    //call api
    try {
      console.log(formValues)
      const res = await axios.put(
        `${process.env.REACT_APP_API}vehicles/update/${vehicle._id}`,
        { ...formValues },
      );
      setLoading(false)
       dispatch(updateVehicle(res.data))
       setOpenEdit(false)
    } catch (error) {
      setLoading(false)
        console.log(error)
       error.message === "Network Error"?toast.error("Connection to the server failed"):toast.error(error.response.data.error.message);
    }
  };
  return (
                
<div className="fixed inset-0 bg-[rgba(0,0,0,0.5)] flex items-center justify-center z-50 overflow-auto max-h-screen">
<div className="max-w-4xl p-6 mx-auto bg-white rounded-md shadow-md dark:bg-gray-800">
    <div className="flex justify-between">
          <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white">
            Add a new Vehicle
          </h2>
          <AiOutlineClose
          className='cursor-pointer'
            size={30}
            onClick={() => {
                setOpenEdit(false);
            }}
          />
        </div>
    <form onSubmit={handleSubmit(handleAdd)}>
        <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
            <input type="hidden" {...register("userId")}/>
            <div>
                <label className="text-gray-700 dark:text-gray-200" for="vehicleOwner">Vehicle Owner name</label>
                <input id="vehicleOwner" {...register("vehicleOwner")} type="text" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/>
                <p className="text-red-500 text-sm">{errors.vehicleOwner?.message}</p>
            </div>

            <div>
                <label className="text-gray-700 dark:text-gray-200" for="ownerContact">Owner Contact</label>
                <input {...register("ownerContact")} id="ownerContact" type="number" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/>
                <p className="text-red-500 text-sm">{errors.ownerContact?.message}</p>
            </div>

            <div>
                <label className="text-gray-700 dark:text-gray-200" for="vehicleMake">Vehicle Make</label>
                <input id="vehicleMake" {...register("vehicleMake")} type="text" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/>
                <p className="text-red-500 text-sm">{errors.vehicleMake?.message}</p>
            </div>

            <div>
                <label className="text-gray-700 dark:text-gray-200" for="vehicleModel">Vehicle Model</label>
                <input id="vehicleModel" {...register("vehicleModel")} type="text" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/>
                <p className="text-red-500 text-sm">{errors.vehicleModel?.message}</p>
            </div>
            <div>
                <label className="text-gray-700 dark:text-gray-200" for="modelYear">Model Year</label>
                <input id="modelYear" type="number" {...register("modelYear")} className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/>
                <p className="text-red-500 text-sm">{errors.modelYear?.message}</p>
            </div>
            <div>
                <label className="text-gray-700 dark:text-gray-200" for="arrivalDate">Arrival Date</label>
                <input id="arrivalDate" type="date" {...register("arrivalDate")} className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/>
                <p className="text-red-500 text-sm">{errors.arrivalDate?.message}</p>
            </div>

            
        </div>

        <div className="flex justify-end mt-6">
        <button className="px-6 py-3 mt-4 w-full text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#022249] rounded hover:bg-blue-900 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50">
            {loading?<div className="flex justify-center gap-4 items-center"><LoadingButton/> <p>Please wait...</p></div>:"ADD VEHICLE"}
                </button>
        </div>
    </form>
</div>
</div>

        
  )
}

export default UpdateVehicleModal