/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import TablePagination from "../../components/pagination/Pagination";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { mkConfig, generateCsv, download } from "export-to-csv";
import LoadingPage from "../../components/loading/LoadingPage";
import { MdEdit } from "react-icons/md";
import { LuDelete } from "react-icons/lu";
import { MdOutlineAdd } from "react-icons/md";
import { BsFiletypeCsv } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddUsers from "../../components/modals/AddUsers";
import UpdateUser from "../../components/modals/UpdateUser";
import ConfirmDeleteUser from "../../components/modals/ConfirmDeleteUser";
import { blockUser, fetchAll, unBlockUser } from "../../redux/allUsersSlice";
const Users = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchOption, setSearchOption] = useState("username")
  const [record, setRecord] = useState({});
  const [openDelete,setOpenDelete] = useState(false);
  const [postsPerPage, setPostsPerPage] = useState(5);
  const [openEdit, setOpenEdit] = useState(false);
  const csvConfig = mkConfig({ useKeysAsHeaders: true, filename:"Loading Dock Users "+moment().format()   });
  let [filteredData] = useState();
  const {currentUser} = useSelector((state) => state.user)
  const allUsers = useSelector((state) => state.allUsers)
  axios.defaults.headers.common = {
    'Authorization': 'Bearer ' + currentUser.accessToken
};
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fetchUsers = async () => {
    setLoading(true)
    try {
      const res = await axios.get(`${process.env.REACT_APP_API}users`, { withCredentials: true });
      dispatch(fetchAll(res.data.filter((user)=>{
        return user._id !== currentUser._id
      })))
      setLoading(false)
    } catch (error) {
      console.log(error)
      error.response.data.error.status === 401? navigate("/"): toast.error(error.response.data.error.message)
      setLoading(false)
    }
  };
  useEffect(() => {
    fetchUsers();
  }, []);
  const modifiedData = allUsers.allUsers.map(({ ...item }) => ({
    ...item,
    key: item._id,
  }));
  const csvData = allUsers.allUsers.map(({...item}, index)=>({
    no: index+1,
    username: item.username,
    email: item.email,
    phone: item.phone,
    
  }))
  let csv;
  if(csvData.length>0){
     csv = generateCsv(csvConfig)(csvData);
  }
  
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentUsers = modifiedData.slice(firstPostIndex, lastPostIndex);
  console.log(searchOption)
  const handleSelectChange = (e)=>{
    setSearchOption(e.target.value);
  }
  const handleSearch = (e) => {
    let searchValue = e.target.value;
    if (e.target.value === "") {
      fetchUsers();
    } else {
      if(searchOption === "username"){
        filteredData = modifiedData.filter((value) => {
          return value.username.toLowerCase().includes(searchValue.toLowerCase());
        });
      }else if(searchOption === "email"){
        filteredData = modifiedData.filter((value) => {
          return value.email.toLowerCase().includes(searchValue.toLowerCase());
        });
      }
      else if(searchOption === "phone"){
        filteredData = modifiedData.filter((value) => {
          return value.phone.toLowerCase().includes(searchValue.toLowerCase());
        });
      }
     
      
      dispatch(fetchAll(filteredData))
      setCurrentPage(1);
    }
  };

  const handleDelete = (record)=>{
    setOpenDelete(true)
    setRecord(record)
  }
  const handleUpdate = (record)=>{
    setOpenEdit(true)
    setRecord(record)
  }
const handleBlock =async(record)=>{
  try {
    await axios.get(`${process.env.REACT_APP_API}users/block/${record._id}`, { withCredentials: true });
    dispatch(blockUser(record))
  } catch (error) {
    console.log(error)
    toast.error("Error blocking user")
  }
}
  
const handleUnBlock =async(record)=>{
  try {
    await axios.get(`${process.env.REACT_APP_API}users/unblock/${record._id}`, { withCredentials: true });
    dispatch(unBlockUser(record))
  } catch (error) {
    console.log(error)
    toast.error("Error unblocking user")
  }
}
  const [openAdd, setOpenAdd] = useState(false);
  return (
    <>
      <div className="flex flex-col  md:flex-row items-center md:justify-between  md:px-24 px-6">
        <div className="flex gap-4 items-center  md:w-1/2 w-full">
          <select onChange={handleSelectChange} defaultValue="ownerOfBox" className="block  mt-2 w-full placeholder-gray-400/70 dark:placeholder-gray-500 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-blue-300">
            <option value="username">Username</option>
            <option value="email">Email</option>
            <option value="phone">Phone</option>
          </select>
          <input
            type="text"
            onChange={handleSearch}
            placeholder="Search..."
            className="block  mt-2 w-full placeholder-gray-400/70 dark:placeholder-gray-500 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-blue-300"
          />
        </div>

        <div className="flex gap-4 items-center mt-4" >
        <button onClick={()=>setOpenAdd(true)} className="flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#022249] rounded-lg hover:bg-blue-900 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
          <MdOutlineAdd />

          <span className="mx-1">Add</span>
        </button>

        <button onClick={()=>{download(csvConfig)(csv)}} className="flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#022249] rounded-lg hover:bg-blue-900 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
        <BsFiletypeCsv />

          <span className="mx-1">Export</span>
        </button>
        </div>
      </div>
      <p className="md:pl-24 pl-8 mt-2 font-bold tracking-wide">{modifiedData.length}  results</p>
      {currentUsers.length >0?<div className="p-5  bg-scale-100">
        <div className="overflow-auto w-11/12 lg:w-11/12 md:w-full mx-auto rounded-lg shadow hidden lg:block md:block xl:block">
          <table className="w-full">
            <thead className="bg-gray-50 border-b-2 border-gray-200">
              <tr>
                <th className="p-3  whitespace-nowrap text-sm font-semibold tracking-wide text-left">
                  No.
                </th>
                <th className="p-3 whitespace-nowrap text-sm font-semibold tracking-wide text-left">
                  Username
                </th>
                <th className="p-3 whitespace-nowrap text-sm font-semibold tracking-wide text-left">
                  Email
                </th>
                <th className="p-3 whitespace-nowrap text-sm font-semibold tracking-wide text-left">
                  {" "}
                  Phone
                </th>
                
                <th className="p-3 whitespace-nowrap text-sm font-semibold tracking-wide text-left">
                  {" "}
                  User Type
                </th>
                
                <th className="p-3 whitespace-nowrap text-sm font-semibold tracking-wide text-left">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100">
              {currentUsers.map((user, index)=>(
                <tr key={user._id}>
                <td className="p-3 whitespace-nowrap text-sm text-gray-700 font-bold">
                {((currentPage-1)*5)+index+1}
                </td>
                <td className="p-3 whitespace-nowrap text-sm text-gray-700">
                  {user.username}
                </td>
                <td className="p-3 whitespace-nowrap text-sm text-gray-700">
                {user.email}
                </td>
                <td className="p-3 whitespace-nowrap text-sm text-gray-700">
                {user.phone}
                </td>
                
                <td className="p-3 whitespace-nowrap capitalize text-sm text-gray-700">
                {user.userType}
                </td>
               
                
                <td className="flex gap-4 items-center py-3">
                {user.status === 1?<button onClick={()=>handleBlock(user)} className="bg-red-500 px-3 py-1.5 rounded text-white hover:bg-red-900">Block</button>:<button onClick={()=>handleUnBlock(user)} className="bg-[#022249] px-3 py-1.5 rounded text-white hover:bg-blue-900">Unblock</button>}
                  <MdEdit onClick={()=>handleUpdate(user)}  className="text-green-500 cursor-pointer" size={20} />{" "}
                  <LuDelete onClick={()=>handleDelete(user)} className="text-red-500 cursor-pointer" size={20} />
                </td>
              </tr>
              ))}
             
               
           
            </tbody>
          </table>
        </div>

        {/* Tablets */}
        <div className="grid sm:grid-cols-2 gap-6 grid-cols-1 md:hidden">
          {currentUsers.map((user, index)=>(
            <div className="bg-slate-50 space-y-3 p-4 rounded-lg shadow" key={user._id}>
            <div className="grid grid-cols-2 gap-2">
              <p className="p-2 rounded rounded-r-3xl bg-[#022249] text-white whitespace-nowrap text-sm font-semibold tracking-wide">
                No
              </p>
              <p className="p-2 text-sm text-gray-700">{((currentPage-1)*5)+index+1}</p>
              <p className="p-2 rounded rounded-r-3xl bg-[#022249] text-white whitespace-nowrap text-sm font-semibold tracking-wide">
               Username
              </p>
              <p className="p-2 text-sm text-gray-700">{user.username}</p>
              <p className="p-2 rounded rounded-r-3xl bg-[#022249] text-white whitespace-nowrap text-sm font-semibold tracking-wide">
               Email
              </p>
              <p className="p-2 text-sm text-gray-700">{user.email}</p>
              <p className="p-2 rounded rounded-r-3xl bg-[#022249] text-white whitespace-nowrap text-sm font-semibold tracking-wide">
                Phone number
              </p>
              <p className="p-2 text-sm text-gray-700">{user.phone}</p>
              <p className="p-2 rounded rounded-r-3xl bg-[#022249] text-white whitespace-nowrap text-sm font-semibold tracking-wide">
                User Type
              </p>
              <p className="p-2 text-sm capitalize text-gray-700">{user.userType}</p>
             
            </div>
            <div className="flex justify-center gap-4 items-center">
            {user.status === 1?<button onClick={()=>handleBlock(user)} className="bg-red-500 px-3 py-1.5 rounded text-white hover:bg-red-900">Block</button>:<button onClick={()=>handleUnBlock(user)} className="bg-[#022249] px-3 py-1.5 rounded text-white hover:bg-blue-900">Unblock</button>}
              <MdEdit onClick={()=>handleUpdate(user)}  className="text-green-500 cursor-pointer" size={20} />{" "}
              <LuDelete onClick={()=>handleDelete(user)} className="text-red-500 cursor-pointer" size={20} />
            </div>
          </div>
          ))}

         
        </div>
      </div>:(
          <div className="flex items-center mt-6 text-center border rounded-lg h-96 dark:border-gray-700">
            {loading?<div className="block w-48 mx-auto"><LoadingPage/> <p>Loading users...</p></div>: <div className="flex flex-col w-full max-w-sm px-4 mx-auto">
              <div className="p-3 mx-auto text-blue-500 bg-blue-100 rounded-full dark:bg-gray-800">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
                </svg>
              </div>
              <h1 className="mt-3 text-lg text-gray-800 dark:text-white">
                No users found
              </h1>
            </div>}
          </div>
        )}
      {openAdd && <AddUsers setOpenAdd={setOpenAdd}/>}
      {openEdit && <UpdateUser setOpenEdit={setOpenEdit} user={record}/>}
      {openDelete && <ConfirmDeleteUser setOpenDelete={setOpenDelete} record ={record} />}
        <ToastContainer/>
<TablePagination setPostsPerPage={setPostsPerPage}  totalPosts={modifiedData.length} postsPerPage={postsPerPage} setCurrentPage={setCurrentPage} currentPage={currentPage}/>
    </>
  );
};


export default Users