import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {string, z } from "zod";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {AiOutlineClose} from "react-icons/ai"
import LoadingButton from "../../components/loading/LoadingButton";
import { addContainer } from "../../redux/containerSlice";
const schema = z.object({
  userId:string().min(5,{
    required_error: "Amount paid is required",
  }),
  containerNumber:string().min(1 ,{
    message: "Container number is required",
  }),
  companyName:string().min(3,{
    message: "Company name is required",
  }),
  pitchUpDate:string().min(1,{
    message: "Pitch up date is required",
  }),
  departureDate:string().min(1,{
    message: "Departure date is required",
  }),
  amountPaid:string().min(1,{
    message: "Amount paid is required",
  }),
  amountOwe:string().min(1,{
    message: "Amount owned is required",
  }),
  
});
const AddContainerModal = ({setOpenAdd}) => {
  const {currentUser} = useSelector((state) => state.user)
  const { register, handleSubmit, formState } = useForm({
    defaultValues: { userId:currentUser._id,containerNumber: "", companyName: "", pitchUpDate:"", departureDate:"", amountOwe:"", amountPaid:""},
    resolver: zodResolver(schema),
  });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false) 
  const { errors } = formState;
  
  axios.defaults.headers.common = {
    'Authorization': 'Bearer ' + currentUser.accessToken
};

  const handleAdd = async (formValues) => {
    setLoading(true)
    //call api
    try {
      console.log(formValues)
      const res = await axios.post(
        `${process.env.REACT_APP_API}containers/new`,
        { ...formValues },
      );
      setLoading(false)
       dispatch(addContainer(res.data))
       setOpenAdd(false)
    } catch (error) {
      setLoading(false)
        console.log(error)
       error.message === "Network Error"?toast.error("Connection to the server failed"):toast.error(error.response.data.error.message);
    }
  };
  return (
                
<div className="fixed inset-0 bg-[rgba(0,0,0,0.5)] flex items-center justify-center z-50 overflow-auto max-h-screen">
<div className="max-w-4xl p-6 mx-auto bg-white rounded-md shadow-md dark:bg-gray-800">
    <div className="flex justify-between">
          <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white">
            Add a new Container
          </h2>
          <AiOutlineClose
          className='cursor-pointer'
            size={30}
            onClick={() => {
              setOpenAdd(false);
            }}
          />
        </div>
    <form onSubmit={handleSubmit(handleAdd)}>
        <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
            <input type="hidden" {...register("userId")}/>
            <div>
                <label className="text-gray-700 dark:text-gray-200" for="containerNumber">Container number</label>
                <input id="containerNumber" {...register("containerNumber")} type="text" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/>
                <p className="text-red-500 text-sm">{errors.containerNumber?.message}</p>
            </div>

            <div>
                <label className="text-gray-700 dark:text-gray-200" for="companyName">Company name</label>
                <input {...register("companyName")} id="companyName" type="text" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/>
                <p className="text-red-500 text-sm">{errors.companyName?.message}</p>
            </div>

            <div>
                <label className="text-gray-700 dark:text-gray-200" for="pitchUpDate">Container pitch up date</label>
                <input id="pitchUpDate" {...register("pitchUpDate")} type="date" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/>
                <p className="text-red-500 text-sm">{errors.pitchUpDate?.message}</p>
            </div>

            <div>
                <label className="text-gray-700 dark:text-gray-200" for="departureDate">Container departure date</label>
                <input id="departureDate" {...register("departureDate")} type="date" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/>
                <p className="text-red-500 text-sm">{errors.departureDate?.message}</p>
            </div>
            <div>
                <label className="text-gray-700 dark:text-gray-200" for="amountPaid">Amount Paid</label>
                <input id="amountPaid" type="number" {...register("amountPaid")} className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/>
                <p className="text-red-500 text-sm">{errors.amountPaid?.message}</p>
            </div>

            <div>
                <label className="text-gray-700 dark:text-gray-200" for="amountOwed">Amount owe</label>
                <input id="amountOwed" type="number" {...register("amountOwe")} className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/>
                <p className="text-red-500 text-sm">{errors.amountOwe?.message}</p>
            </div>
        </div>

        <div className="flex justify-end mt-6">
        <button className="px-6 py-3 mt-4 w-full text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#022249] rounded hover:bg-blue-900 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50">
            {loading?<div className="flex justify-center gap-4 items-center"><LoadingButton/> <p>Please wait...</p></div>:"ADD CONTAINER"}
                </button>
        </div>
    </form>
</div>
</div>

        
  )
}

export default AddContainerModal