import React from 'react'
import { IoLogoFacebook } from "react-icons/io5";
import { FaInstagram } from "react-icons/fa";
import { CiLinkedin } from "react-icons/ci";
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <>
        <div className='sticky md:fixed bottom-0 left-0 right-0 md:h-14 h-20 bg-[#022249]'>
            <div className='flex flex-col md:flex-row items-center md:px-10 justify-between'>
                <div className='text-white text-sm flex flex-col items-center h-14 justify-center'>
                    <p>{new Date().getFullYear()} Loading Dock System</p>
                    <p>All Rights Reserved</p>
                </div>
                <div className='flex items-center gap-6 md:w-1/4 justify-around'>
                    <Link to="https://www/facebook.com"><IoLogoFacebook size={24} className='text-white' /></Link>
                    <Link to="https://www.instagram.com/"><FaInstagram size={24} className='text-white' /></Link>
                    <Link to="https://www.linkedin.com/"><CiLinkedin  size={24} className='text-white'/></Link>
                </div>
            </div>
        </div>
    </>
  )
}

export default Footer