
import { createSlice } from '@reduxjs/toolkit';

const allUserSlice = createSlice({
  name: 'allUsers',
  initialState: {
    allUsers: [],
  },
  reducers: {
    addUser: (state, action) => {
        // add a new box
        state.allUsers.push({ ...action.payload });
      
    },
    fetchAll: (state, action)=>{
        state.allUsers = action.payload;
    },
    updateUser: (state, action)=>{
      const item = state.allUsers.find((item) => item._id === action.payload._id);
        item.username = action.payload.username
        item.email = action.payload.email
        item.phone = action.payload.phone
        
    },
    blockUser: (state, action)=>{
      const item = state.allUsers.find((item) => item._id === action.payload._id);
        item.status = 0
        
        
    },
    unBlockUser: (state, action)=>{
      const item = state.allUsers.find((item) => item._id === action.payload._id);
        item.status = 1
        
        
    },
    removeUser: (state, action) => {
      const removeItem = state.allUsers.filter((item) => item._id !== action.payload._id);
      state.allUsers = removeItem;
    },
    
  },
});

export const allUserReducer = allUserSlice.reducer;
export const {
  addUser,
  updateUser,
  fetchAll,
  removeUser,
  blockUser,
  unBlockUser
} = allUserSlice.actions;