
import { createSlice } from '@reduxjs/toolkit';

const containerSlice = createSlice({
  name: 'container',
  initialState: {
    container: [],
  },
  reducers: {
    deleteAll:(state)=>{
        state.container =[]
    },
    addContainer: (state, action) => {
        // add a new container
        state.container.push({ ...action.payload });
      
    },
    fetchAll: (state, action)=>{
        state.container = action.payload;
    },
    updateContainerFiles:(state, action)=>{
      const item = state.container.find((item) => item._id === action.payload._id);
      item.files.push({fileName: action.payload.fileName, fileLink: action.payload.fileLink})
    },
    updateContainer: (state, action)=>{
      const item = state.container.find((item) => item._id === action.payload._id);
        item.companyName = action.payload.companyName
        item.containerNumber = action.payload.containerNumber
        item.pitchUpDate = action.payload.pitchUpDate
        item.departureDate = action.payload.departureDate
        item.amountPaid = action.payload.amountPaid
        item.amountOwe = action.payload.amountOwe
    },
    
    removeItem: (state, action) => {
      const removeItem = state.container.filter((item) => item._id !== action.payload._id);
      state.container = removeItem;
    },
    deleteContainerFile: (state, action)=>{
      const item = state.container.find((item) => item._id === action.payload._id);
      item.files.splice(item.files.findIndex((f)=> f.fileName===action.payload.fileName),1)
    }
    
  },
});

export const containerReducer = containerSlice.reducer;
export const {
  addContainer,
  updateContainer,
  fetchAll,
  removeItem,
  updateContainerFiles,
  deleteContainerFile,
  deleteAll
} = containerSlice.actions;