// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyB2EvM8t0lDqiFP04BEvPB4LaWDiL9DwXE",
    authDomain: "loading-dock-6a96a.firebaseapp.com",
    projectId: "loading-dock-6a96a",
    storageBucket: "loading-dock-6a96a.appspot.com",
    messagingSenderId: "387857075281",
    appId: "1:387857075281:web:150d18b0a0d6003fc73c70"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const provider = new GoogleAuthProvider();
export default app;