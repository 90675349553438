import React from 'react'
import { Link } from 'react-router-dom'

const InvalidLink = () => {
  return (
    <div className='flex flex-col justify-center h-screen items-center'>
        <h1 className=' p-4 text-center font-bold text-2xl'>Invalid Link</h1>
        <p>The link is invalid. Please try again or go back to login</p>
        <Link to="/" className='bg-[#022249] px-3 py-1.5 rounded text-white hover:bg-blue-900'>Login</Link>
    </div>
  )
}

export default InvalidLink