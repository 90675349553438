import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {string, z } from "zod";
import { ToastContainer, toast } from "react-toastify";
import {  useSelector } from 'react-redux';
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import LoadingButton from "../../components/loading/LoadingButton";
const schema = z.object({
 
    title:string().min(1 ,{
      message: "Title is required",
    }),
    message:string().min(3,{
      message: "Message is required",
    }),
    
  
  
    
  });
const ContactPage = () => {
    const {currentUser} = useSelector((state) => state.user)
    const { register, handleSubmit, formState } = useForm({
      defaultValues: { username: currentUser.username, email: currentUser.email, phone:currentUser.phone, password:""},
      resolver: zodResolver(schema),
    });
    const [loading, setLoading] = useState(false) 
    const { errors } = formState;
    
    axios.defaults.headers.common = {
      'Authorization': 'Bearer ' + currentUser.accessToken
  };
  
    const handleSend = async (formValues) => {
      setLoading(true)
      //call api
      try {
        console.log(formValues)
         await axios.post(
          `${process.env.REACT_APP_API}users/send`,
          { ...formValues, clientEmail: currentUser.email, name: currentUser.username },
        );
        toast.success("Message sent. We will respond soon:)")
        setLoading(false)
      } catch (error) {
        setLoading(false)
          console.log(error)
         error.message === "Network Error"?toast.error("Connection to the server failed"):toast.error(error.response.data.error.message);
      }
    };
    return (
      <div className="max-w-4xl p-6  mx-auto bg-white rounded-md shadow-md dark:bg-gray-800">
      <div className="flex justify-between">
            <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white">
              Send Message
            </h2>
            
          </div>
      <form onSubmit={handleSubmit(handleSend)}>
          <div className="grid grid-cols-1 gap-6 mt-4">
              <input type="hidden" {...register("userId")}/>
              <div>
                  <label className="text-gray-700 dark:text-gray-200" for="title">Title</label>
                  <input id="title" {...register("title")} type="text" className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"/>
                  <p className="text-red-500 text-sm">{errors.title?.message}</p>
              </div>
  
              <div>
                  <label className="text-gray-700 dark:text-gray-200" for="message">Message</label>
                  <textarea {...register("message")} id="message" rows={5} className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"></textarea>
                  <p className="text-red-500 text-sm">{errors.message?.message}</p>
              </div>
  
             
  
              
          </div>
  
          <div className="flex justify-end mt-6">
          <button className="px-6 py-3 mt-4 w-full text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#022249] rounded hover:bg-blue-900 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50">
              {loading?<div className="flex justify-center gap-4 items-center"><LoadingButton/> <p>Please wait...</p></div>:"SEND MESSAGE"}
                  </button>
          </div>
      </form>
      <ToastContainer/>
  </div>
    )
  }

export default ContactPage