import React from 'react'
import success  from "../../images/success.png"
const EmailSentVerification = ({email}) => {
  return (
    <div className='flex flex-col h-screen bg-blue-200 justify-center items-center'>
        <img src={success} className='h-96' alt="" />
        <h1 className='font-bold text-3xl tracking-widest text-center'>Reset Password Link Sent</h1>
        <p className='p-4 tracking-wide text-slate-500'>Check your email to  get the password reset link.</p>
    </div>
  )
}

export default EmailSentVerification