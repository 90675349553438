import React from 'react'
import { RiUserLine } from "react-icons/ri";
import { IoMdLogOut } from "react-icons/io";
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Login from '../../pages/auth/Login';
import { logout } from '../../redux/userSlice';
import iconWhite from "../../images/icon-white.png"
const Nav = () => {
 
  const pathName = useLocation().pathname.split("/")[2]

  const {currentUser} = useSelector((state) => state.user)
  const dispatch = useDispatch();
  if(currentUser == null || currentUser === undefined){
      return <Login/>
  }
  const handleLogout = ()=>{
      //remove currentuser
      dispatch(logout());
      //return login
      return <Login/>
  }
return (
  <>
      <div className='sticky top-0 left-0 right-0 z-40' >
      <div className='h-20 bg-[#022249] flex items-center justify-between md:px-10 px-3'>
          <div className=''><img src={iconWhite} className='md:h-20 h-12' alt="Loading dock icon"/></div>
          <div className='flex items-center gap-12 justify-between'>
              <div className='flex items-center gap-6'>
                  <RiUserLine className='text-white' size={24} />
                  <p className='text-white font-bold hidden md:block'>{currentUser.username}</p>
              </div>
              <div onClick={handleLogout} className='flex items-center gap-6 cursor-pointer'>
                  <IoMdLogOut className='text-white' size={24} />
                  <p className='text-white font-bold hidden md:block'>Log out</p>
              </div>
          </div>
      </div>
      <div className='flex bg-white border-b border-solid border-slate-500 h-20 justify-around items-center'>
         
          <Link to="" className={pathName === undefined?'uppercase font-bold border-b border-solid border-[#F07C41] pb text-[#F07C41] tracking-widest':'uppercase font-bold hover:text-[#F07C41] tracking-widest'}>Users</Link>
          <Link to="account" className={pathName === "account"?'uppercase font-bold border-b border-solid border-[#F07C41] pb text-[#F07C41] tracking-widest':'uppercase font-bold hover:text-[#F07C41] tracking-widest'}>Profile</Link>
      </div>

      
  </div>
  <Outlet/>
  </>
)
}

export default Nav