import { BrowserRouter, Routes, Route } from "react-router-dom";
import PageNotFound from "./pages/errors/PageNotFound";
import MainPage from "./pages/clients/MainPage";
import Header from "./components/containers/Header";
import Login from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Containers from "./pages/clients/Containers";
import Boxes from "./pages/clients/Boxes";
import Vehicles from "./pages/clients/Vehicles";
import ContactPage from "./pages/clients/ContactPage";
import AccountSettings from "./pages/clients/AccountSettings";
import Nav from "./components/navs/Nav";
import Users from "./pages/admin/Users";
import AdminProfile from "./pages/admin/AdminProfile";
import ResetPassword from "./pages/auth/ResetPassword";
import EmailSentVerification from "./pages/auth/EmailSentVerification";
import PasswordResetSuccess from "./pages/auth/PasswordResetSuccess";
import NoConnection from "./pages/errors/NoConnection";

function App() {
  return (
    <BrowserRouter>
      <Routes>
     
          <Route index element={<Login />} />
          <Route path="forgot" element={<ForgotPassword />} />
          <Route path="noconnection" element={<NoConnection />} />
          <Route path="forgot/success" element={<EmailSentVerification />} />
          <Route path="password-reset/*" element={<ResetPassword />} />
          <Route path="password-reset/success" element={<PasswordResetSuccess />} />
          <Route path="*" element={<PageNotFound />} />
      

        <Route path="/admin" element={<Nav />}>
          <Route index element={<Users />} />
          <Route path="account" element={<AdminProfile />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>

        <Route path="/loading-dock" element={<Header />}>
          <Route index element={<MainPage />} />
          <Route path="containers" element={<Containers />} />
          <Route path="boxes" element={<Boxes />} />
          <Route path="vehicles" element={<Vehicles />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="account" element={<AccountSettings />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
